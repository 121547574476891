<template>
  <div>
    <el-form :inline="true" size="mini">
      <el-form-item label="项目">
        <project-select v-model="projectId"></project-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh"
          >刷新</el-button
        >
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      stripe
      border
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        label="编号"
        prop="goodsCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="物资"
        prop="goodsName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="规格"
        prop="goodsSpec"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="单位"
        prop="goodsUnit"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="计划数量" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.palnQuantity | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="采购数量" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.realQuantity | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="采购金额" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.realAmount | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="差额" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.deff | thousands }}</template>
      </el-table-column>
      <el-table-column label="完成率" show-overflow-tooltip>
        <template slot-scope="scope"
          >{{ scope.row.percent | thousands }}%</template
        >
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect,
  },
  data() {
    return {
      projectId: "",
      data: [],
    };
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.deff < 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getData() {
      if (!this.projectId) {
        this.$alert("请选择项目", "提示", {
          type: "error",
        });
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("GoodsPlan/PlanExecute", {
        projectId: this.projectId,
      })
        .then((r) => {
          (r || []).forEach((item) => {
            item.deff = item.palnQuantity - (item.realQuantity || 0);
            item.percent = ((item.realQuantity || 0) / item.palnQuantity) * 100;
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style>
</style>